<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="120px"
                    label-suffix=":"
            >
                <el-form-item label="关联应用" prop="appId">
                    <el-select v-model="detailForm.appId" placeholder="请选择">
                        <el-option  v-for="(item,i) in productList" :key="i" :label="item.appName" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="更新范围" required prop="beginVersion">
                    <div class="diy_row">
                        <el-input
                                v-model="detailForm.beginVersion"
                                placeholder="请输入"
                                clearable
                        />
                        <span class="diy_span">-</span>
                        <el-input
                                v-model="detailForm.endVersion"
                                placeholder="请输入"
                                clearable
                        />
                    </div>
                    <p class="note">注：哪些版本会提示更新</p>

                </el-form-item>
                <el-form-item label="版本号" prop="finalVersion">
                    <el-input v-model="detailForm.finalVersion" placeholder="请输入"/>
                    <p class="note">注：更新至哪个版本</p>

                </el-form-item>
                <el-form-item label="升级描述" prop="upgradeDescription">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4}"
                            :maxlength="300"
                            show-word-limit
                            placeholder="请输入"
                            v-model="detailForm.upgradeDescription" />
                </el-form-item>

                <el-form-item label="更新类型" prop="updateType">
                    <el-select v-model="detailForm.updateType" placeholder="请选择">
                        <el-option value="FORCE" label="强制" />
                        <el-option value="NORMAL" label="正常" />
                    </el-select>
                </el-form-item>
                <el-form-item label="下载地址" prop="downloadUrl">
                    <el-input v-model="detailForm.downloadUrl" placeholder="请输入下载地址"/>
                </el-form-item>
                <el-form-item label="平台类型" prop="platformType">
                    <el-select v-model="detailForm.platformType" placeholder="请选择">
                        <el-option value="android" label="android" />
                        <el-option value="ios" label="ios" />
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="detailForm.status" placeholder="请选择">
                        <el-option :value="0" label="禁止" />
                        <el-option :value="1" label="开启" />
                    </el-select>
                </el-form-item>


                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {getAppVersionConfig, saveAppVersionConfig,getAppList} from "@/api/public";


    export default {
        data() {
            var validateVer = (rule, value, callback) => {
                if (!this.detailForm.beginVersion || !this.detailForm.endVersion) {
                    callback(new Error('请完善更新范围'));
                }else {
                    callback();
                }
            };
            return {
                pageLoad: false,
                title: "",
                productList:[],
                detailForm: {
                    appId: null,
                    beginVersion: "",
                    downloadUrl: "",
                    endVersion: "",
                    finalVersion: "",
                    id: this.$route.query.id || null,
                    platformType: "",
                    status: 0,
                    updateType: "",
                    upgradeDescription: ""
                },
                detailRules: {
                    appId:[{required: true, message: "请选择关联应用",trigger:'change'}],
                    beginVersion:[ { validator: validateVer, trigger: 'blur' }],
                    finalVersion: [{required: true, message: "请输入版本号"}],
                    upgradeDescription: [{required: true, message: "请输入升级描述"}],
                    updateType: [{required: true, message: "请选择更新类型",trigger:'change'}],
                    downloadUrl: [{required: true, message: "请输入下载地址"}],
                    platformType:[{required: true, message: "请选择平台类型",trigger:'change'}]
                },
                saveLoad: false,
            };
        },
        created() {
            this.title = this.$route.query.id ? "编辑版本" : "新增版本";
            this.getAppList();
            this.detailForm.id && this.getAppVersionConfig();
        },
        methods: {
            changeTime(time) {
                if (time && time.length) {
                    this.detailForm.startTime = time[0];
                    this.detailForm.endTime = time[1];
                } else {
                    this.detailForm.startTime = "";
                    this.detailForm.endTime = "";
                }
            },

            getAppList() {
                getAppList({
                    pageNum: 1,
                    pageSize: 999,
                }).then((res) => {
                    this.productList = res.data.records;
                })
            },

            //新增banner
            saveAppVersionConfig(form) {
                this.saveLoad = true;
                saveAppVersionConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('保存成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //新增banner
            // updateBanner(form) {
            //     this.saveLoad = true;
            //     updateBanner(
            //         form
            //     ).then(() => {
            //         this.saveLoad = false;
            //         this.cancel()
            //         this.$message.success('更新成功')
            //     }).catch(() => {
            //         this.saveLoad = false;
            //     })
            // },

            getAppVersionConfig() {
                this.pageLoad = true;
                getAppVersionConfig({id: this.detailForm.id})
                    .then((res) => {
                        Object.assign(this.detailForm, res.data);
                        this.pageLoad = false;
                    })
                    .catch(() => {
                        this.pageLoad = false;
                    });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        // if (this.detailForm.id) {
                        //     this.updateBanner(form)
                        // } else {
                            this.saveAppVersionConfig(this.detailForm)
                        // }
                    }
                });
            },
            cancel() {
                this.$router.push("/version");
            },

        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }

    .diy_row {
        display: flex;
        align-items: center;
    }

    .diy_span {
        margin: 0 10px;
    }

    .note {
        color: #a2a3a5;
    }
</style>
